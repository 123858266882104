<template>
    <div class="row pb-2 gy-1 mt-3">
        <h5 class="mb-1 mt-2 col-12 border-bottom">{{$t("Recognition")}}</h5>
        <div class="col-6">
            <label v-if="!readOnly" class="form-label">{{$t("Nominee")}}</label>
            <b v-else >{{$t("Nominee")}}: </b>
            <PersonsLookup v-if="!readOnly"
                :bind="sel => { dsRecognition.current.Person_ID = sel.ID, dsRecognition.current.Nominee = sel.Name; dsRecognition.save()}"
                v-model="dsRecognition.current.Nominee" >
                <template #target="{target}">
                    <input type="text" v-model="dsRecognition.current.Nominee" :ref="target" class="form-control form-control-sm" >
                </template>
            </PersonsLookup>
            <span v-else >{{dsRecognition.current.Nominee}}</span>
        </div> 
        <div class="col-6">
            <label v-if="!readOnly" class="form-label">{{$t("Nominated By")}}</label>
            <b v-else >{{$t("Nominated By")}}: </b>
            <PersonsLookup v-if="!readOnly"
                :bind="sel => { dsRecognition.current.NominatedBy_ID = sel.ID, dsRecognition.current.NominatedBy = sel.Name; dsRecognition.save()}"
                v-model="dsRecognition.current.NominatedBy" >
                <template #target="{target}">
                    <input type="text" v-model="dsRecognition.current.NominatedBy" :ref="target" class="form-control form-control-sm" :readOnly="readOnly ? true : false">
                </template>
            </PersonsLookup>
            <span v-else >{{dsRecognition.current.NominatedBy}}</span>
        </div>


        <div class="col-col">
            <label v-if="!readOnly" class="form-label">{{$t("Comment")}}</label>
            <b v-else >{{$t("Comment")}}: </b>
            <OTextArea v-if="!readOnly" v-model="dsRecognition.current.Comment" @blur="dsRecognition.save();" class="border d-inline-block p-1 bg-white  form-control" autoGrow noResize style="min-height:70px" />
            <span v-else>{{dsRecognition.current.Comment}}</span>
        </div>

        <div class="col-6">
            <label v-if="!readOnly" class="form-label">{{$t("Reason For Nomination")}}</label>
            <b v-else >{{$t("Reason For Nomination")}}: </b>
            
            <ODataLookup class="form-control form-control-sm" v-if="!readOnly" 
                :data-object="dsReasons" 
                :value="dsRecognition.current.Reason"
                :bind="selected => {dsRecognition.current.Reason_ID = selected.ID, dsRecognition.current.Reason = selected.Name; dsRecognition.save()}"
                v-model="dsRecognition.current.Reason">
                <OColumn field="Name" width="300"></OColumn>
                <template #target="{target}">
                    <input v-if="!readOnly" type="text" v-model="dsRecognition.current.Reason" class="form-control form-control-sm" :ref="target" >
                </template>
            </ODataLookup>
            <span v-else >{{dsRecognition.current.Reason}}</span>
        </div>
        
        <div class="col-6">
            <label v-if="!readOnly" class="form-label">{{$t("Item / Present")}}</label>
            <b v-else >{{$t("Item / Present")}}: </b>

            <ODataLookup class="form-control form-control-sm" v-if="!readOnly" 
                :data-object="dsItems" 
                :value="dsRecognition.current.Item"
                :bind="selected => {dsRecognition.current.Item_ID = selected.ID, dsRecognition.current.Item = selected.Name; dsRecognition.save()}"
                v-model="dsRecognition.current.Item">
                <OColumn field="Name" width="300"></OColumn>
                <template #target="{target}">
                    <input type="text" v-model="dsRecognition.current.Item" class="form-control form-control-sm" :ref="target" />
                </template>
            </ODataLookup>
            <span v-else >{{dsRecognition.current.Item}}</span>
        </div>

        <div class="col-12 mt-2">
            <label v-if="!readOnly" class="form-label">{{$t("Date Of Event")}}</label>
            <b v-else >{{$t("Date Of Event")}}: </b>
            <DatePicker v-if="!readOnly" v-model="dsRecognition.current.DateOfEvent" format="Short Date"  class="form-control form-control-sm"  :clearable="true"/>
            <span v-else >{{dsRecognition.current.DateOfEvent}}</span>
        </div>

        <div class="col-12 mt-2">
            <label v-if="!readOnly" class="form-label">{{$t("Text On Card")}}</label>
            <b v-else >{{$t("Text On Card")}}: </b>
            <OTextArea v-if="!readOnly" v-model="dsRecognition.current.TextOnCard" @blur="dsRecognition.save();" class="border d-inline-block p-1 bg-white  form-control" :readOnly="readOnly ? true : false" autoGrow noResize style="min-height:70px" />
            <span v-else >{{dsRecognition.current.TextOnCard}}</span>
        </div>
    </div>
</template>

<script setup>   
    import { ref, defineProps } from 'vue';
    import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
    import PersonsLookup from 'o365.vue.components.PersonsLookup.vue';
    import DatePicker from 'o365.vue.components.DatePicker.vue';

    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly:Boolean
    });
    

    const counter = ref(0);

    const dsRecognition = getOrCreateDataObject({
        id: 'dsRecognition',
        viewName: 'aviw_Jobs_RecognitionForWorkflow',
        maxRecords: 1,
        uniqueTable: 'atbv_Jobs_Recognitions',
        allowInsert: true,
        allowDelete: true,
        allowUpdate: true,
        loadRecents: false,
        distinctRows: true,
        selectFirstRowOnLoad: true,
        fields:
             [{name: "ID", type: "number" },
             {name: "Nominee", type: "string" },
             {name: "Person_ID", type: "number" },
             {name: "NominatedBy", type: "string" },
             {name: "NominatedBy_ID", type: "number" },
             {name: "Comment", type: "string" },
             {name: "Reason", type: "string" },
             {name: "Reason_ID", type: "number" },
             {name: "Item", type: "string" },
             {name: "Item_ID", type: "number" },
             {name: "DateOfEvent", type: "datetime" },
             {name: "TextOnCard", type: "string" },]
    });

    const dsItems = getOrCreateDataObject({
        id: 'dsItems',
        viewName: 'atbv_Jobs_RecognitionsItems',
        maxRecords: 1,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number" },
             {name: "Name", type: "string" }]
    });

    const dsReasons = getOrCreateDataObject({
        id: 'dsReasons',
        viewName: 'aviw_Jobs_RecognitionsReasons',
        maxRecords: 1,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number" },
             {name: "Name", type: "string" }]
    });

    dsRecognition.on("FieldChanged", (pField)=>{
        if(pField === 'DateOfEvent'){
            dsRecognition.save();
        }
    });

    const procGetRecId = getOrCreateProcedure({ id:"o365_procGetRecId", procedureName:"astp_Jobs_GetRecognition" });
    procGetRecId.execute({Item_ID:props.itemId}).then((pData)=>{
        dsRecognition.recordSource.whereClause = "ID ="+pData.Table[0].ID;
        dsRecognition.load();
    });
</script>